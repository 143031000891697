import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControlLabel,
  Checkbox,
  NativeSelect,
} from "@material-ui/core";
import Container from "@material-ui/core/Container/Container";
import GetAppIcon from "@material-ui/icons/GetApp";
import { parse as parseCsv, unparse as unparseCsv } from "papaparse";
import { useTranslate } from "ra-core";
import React, { useState } from "react";
import {
  Button as ReactAdminButton,
  Title,
  useDataProvider,
  useNotify,
} from "react-admin";
import { EUserThreePID, ExistedUserField } from '../enum/EThreePID';
import { EPlatform } from '../enum/EUserSettings';
// import userProvider from "../synapse/userProvider";
import { logger } from '../utils/logger';
import { generateRandomUser } from "./users";
import { sortAlphabetASC, stringToArray, validateArrayValuesInEnum } from '../helper/utils';

const LOGGING = true;

export const ImportButton = ({ label, variant = "text" }) => {
  return (
    <ReactAdminButton
      color="primary"
      component="span"
      variant={variant}
      label={label}
    >
      <GetAppIcon style={{ transform: "rotate(180deg)", fontSize: "20" }} />
    </ReactAdminButton>
  );
};

const expectedFields = sortAlphabetASC(["phone", "email", "displayname"]);
const optionalFields = sortAlphabetASC([
  "user_type",
  "is_guest",
  "username",
  "admin",
  "deactivated",
  "avatar_url",
  "password",
  "platforms",
]);

function TranslatableOption({ value, text }) {
  const translate = useTranslate();
  return <option value={value}>{translate(text)}</option>;
}

const FilePicker = props => {
  const [values, setValues] = useState(null);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState(null);
  const [dryRun, setDryRun] = useState(false);

  const [progress, setProgress] = useState(null);

  const [importResults, setImportResults] = useState(null);
  const [skippedRecords, setSkippedRecords] = useState(null);

  const [conflictMode, setConflictMode] = useState("skip");
  const [passwordMode, setPasswordMode] = useState(true);
  const [useridMode, setUseridMode] = useState("update");

  const translate = useTranslate();
  const notify = useNotify();

  const dataProvider = useDataProvider();

  const onFileChange = async e => {
    if (progress !== null) return;

    setValues(null);
    setError(null);
    setStats(null);
    setImportResults(null);
    const file = e.target.files ? e.target.files[0] : null;
    /* Let's refuse some unreasonably big files instead of freezing
     * up the browser */

    /*
     * Accept file extensions: .csv
     */
    if (!file || !file.name.match(/\.csv$/i)) {
      const message = translate("import_users.error.accept_file_extension");
      notify(message);
      setError(message);
      return;
    }

    /*
     * Allow file size <= 25M
     */
    /*
    if (file.size > 25600) {
      const message = translate("import_users.error.unreasonably_big", {
        size: (file.size / (1024 * 1024)).toFixed(2),
      });
      notify(message);
      setError(message);
      return;
    }*/
    try {
      parseCsv(file, {
        header: true,
        skipEmptyLines: true /* especially for a final EOL in the csv file */,
        complete: result => {
          if (result.error) {
            setError(result.error);
          }
          /* Papaparse is very lenient, we may be able to salvage
           * the data in the file. */
          verifyCsv(result, { setValues, setStats, setError });
        },
      });
    } catch {
      setError(true);
      return null;
    }
  };

  const verifyCsv = (
    { data, meta, errors },
    { setValues, setStats, setError }
  ) => {
    /* First, verify the presence of required fields */
    let eF = Array.from(expectedFields);
    let oF = Array.from(optionalFields);

    meta.fields.forEach(name => {
      if (eF.includes(name)) {
        eF = eF.filter(v => v !== name);
      }
      if (oF.includes(name)) {
        oF = oF.filter(v => v !== name);
      }
    });

    if (eF.length !== 0) {
      setError(
        translate("import_users.error.required_field", { field: eF[0] })
      );
      return false;
    }

    // XXX after deciding on how "name" and friends should be handled below,
    //     this place will want changes, too.

    /* Collect some stats to prevent sneaky csv files from adding admin
       users or something.
     */
    let stats = {
      user_types: { default: 0 },
      is_guest: 0,
      admin: 0,
      deactivated: 0,
      password: 0,
      avatar_url: 0,
      id: 0,
      phone: 0,
      email: 0,
      username: 0,
      platforms: 0,
      total: data.length,
    };

    data.forEach((line, idx) => {
      if (line.user_type === undefined || line.user_type === "") {
        stats.user_types.default++;
      } else {
        stats.user_types[line.user_type] += 1;
      }
      /* XXX correct the csv export that react-admin offers for the users
       * resource so it gives sensible field names and doesn't duplicate
       * id as "name"?
       */
      if (meta.fields.includes("name")) {
        delete line.name;
      }
      if (meta.fields.includes("user_type")) {
        delete line.user_type;
      }
      if (meta.fields.includes("is_admin")) {
        line.admin = line.is_admin;
        delete line.is_admin;
      }

      ["is_guest", "admin", "deactivated"].forEach(f => {
        if (meta.fields.includes(f)) {
          if (line[f] === "true") {
            stats[f]++;
            line[f] = true; // we need true booleans instead of strings
          } else {
            if (line[f] !== "false" && line[f] !== "") {
              errors.push(
                translate("import_users.error.invalid_value", {
                  field: f,
                  row: idx,
                })
              );
            }
            line[f] = false; // default values to false
          }
        }
      });

      if (line.password !== undefined && line.password !== "") {
        stats.password++;
      }

      if (line.avatar_url !== undefined && line.avatar_url !== "") {
        stats.avatar_url++;
      }

      if (line.id !== undefined && line.id !== "") {
        stats.id++;
      }

      if ((line.phone || "").trim() !== "") {
        line.phone = line.phone.trim();
        stats.phone++;
        // data[idx].displayname = `ctalk_${line.phone}`;
        if (!line.email) {
          data[idx].id = `ctalk_${line.phone}`;
        }
      }

      if (line.email !== undefined && line.email !== "") {
        stats.email++;
        line.email = line.email.trim();
        data[idx].id = line.id?.trim() || undefined;
        // if (!data[idx].displayname) {
        //   data[idx].displayname = line.email;
        // }
      }

      if (line.username !== undefined && line.username !== "") {
        stats.username++;
      }

      if (line.platforms !== undefined && line.platforms !== "") {
        stats.platforms++;
      }

    });

    // if (errors.length > 0) {
    //   setError(errors);
    // }
    setStats(stats);
    setValues(data);

    return true;
  };

  const runImport = async e => {
    if (progress !== null) {
      notify("import_users.error.already_in_progress");
      return;
    }

    const results = await doImport(
      dataProvider,
      values,
      conflictMode,
      passwordMode,
      useridMode,
      dryRun,
      setProgress,
      setError
    );
    setImportResults(results);
    // offer CSV download of skipped or errored records
    // (so that the user doesn't have to filter out successful
    // records manually when fixing stuff in the CSV)
    setSkippedRecords(unparseCsv(results.skippedRecords));
    if (LOGGING) logger.log("Skipped records:");
    if (LOGGING) logger.log(skippedRecords);
  };

  // XXX every single one of the requests will restart the activity indicator
  //     which doesn't look very good.
  const isNumeric = value => {
    return /^-?\d+$/.test(value);
  };

  const validUsername = (value) => {
    // noinspection RegExpDuplicateCharacterInClass
    const USERNAME_REGEX = /^(?=.{5,32}$)(?!.*__)(?!^([cC][tT][aA][lL][kK]|[aA][dD][mM][iI][nN]|[sS][uU][pP][pP][oO][rR][tT]))[(a-zA-Z)(A-Za-z)][(a-zA-Z0-9_)(A-Za-z0-9_)]*[(a-zA-Z0-9)(A-Za-z0-9)]$|^$/; // eslint-disable-line no-useless-escape
    return new RegExp(USERNAME_REGEX).test(value);
  }

  const isEmail = (value) => {
    const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
    return new RegExp(EMAIL_REGEX).test(value);
  }

  const validDisplayname = (value) => {
    const DISPLAYNAME_MAXLENGTH_REGEX = /^.{1,64}$/; // eslint-disable-line no-useless-escape
    const DISPLAYNAME_SPACES_REGEX = /^\s*$/; // eslint-disable-line no-useless-escape
    return (new RegExp(DISPLAYNAME_MAXLENGTH_REGEX).test(value) && !(new RegExp(DISPLAYNAME_SPACES_REGEX).test(value.trim())));
  }

  const doImport = async (
    dataProvider,
    data,
    conflictMode,
    passwordMode,
    useridMode,
    dryRun,
    setProgress,
    setError
  ) => {
    let skippedRecords = [];
    let erroredRecords = [];
    let succeededRecords = [];
    let changeStats = {
      toAdmin: 0,
      toGuest: 0,
      toRegular: 0,
      replacedPassword: 0,
    };
    let entriesDone = 0;
    let entriesCount = data.length;
    let entriesError;
    
    setProgress({ done: entriesDone, limit: entriesCount });

    let dataFilter = [];

    let entryIsValid;
    for (const entry of data) {
      entryIsValid = true;
      if (!entry.email) {
        skippedRecords.push({
          ...getSkippedRecord(entry),
          cause: "Email is required",
        });
        entryIsValid = false;
      } else if (entry.email?.length && !isEmail(entry.email)) {
        skippedRecords.push({
          ...getSkippedRecord(entry),
          cause: "Email is invalid",
        });
        entryIsValid = false;
      } else if (entry.phone?.length && !isNumeric(entry.phone)) {
        skippedRecords.push({
          ...getSkippedRecord(entry),
          cause: "Phone number is invalid",
        });
        entryIsValid = false;
      } else if (entry.username?.length && !validUsername(entry.username)) {
        skippedRecords.push({
          ...getSkippedRecord(entry),
          cause: "Username is invalid",
        });
        entryIsValid = false;
      } else if (!entry.displayname) {
        skippedRecords.push({
          ...getSkippedRecord(entry),
          cause: "Displayname is required",
        });
        entryIsValid = false;
      } else if (entry.displayname?.length && !validDisplayname(entry.displayname)) {
        skippedRecords.push({
          ...getSkippedRecord(entry),
          cause: "Displayname must be contains characters and 64 characters or less",
        });
        entryIsValid = false;
      } else if (
        entry.platforms &&
        !validateArrayValuesInEnum(stringToArray(entry.platforms), EPlatform)
      ) {
        skippedRecords.push({
          ...getSkippedRecord(entry),
          cause: translate("import_users.error.invalid_platforms"),
        });
        entryIsValid = false;
      }
      if (entryIsValid) {
        dataFilter.push(entry);
      }
    }

    for (const entry of dataFilter) {
      let userRecord = {};
      let overwriteData = {};
      // No need to do a bunch of cryptographic random number getting if
      // we are using neither a generated password nor a generated user id.
      if (
        useridMode === "ignore" ||
        entry.id === undefined ||
        entry.password === undefined ||
        passwordMode === false
      ) {
        overwriteData = generateRandomUser();
        // Ignoring IDs or the entry lacking an ID means we keep the
        // ID field in the overwrite data.
        if (!(useridMode === "ignore" || entry.id === undefined)) {
          delete overwriteData.id;
        }
        // Not using passwords from the csv or this entry lacking a password
        // means we keep the password field in the overwrite data.
        if (
          !(
            passwordMode === false ||
            entry.password === undefined ||
            entry.password === ""
          )
        ) {
          delete overwriteData.password;
        }
      }
      // Build threepids = phone
      const phone = entry.phone;
      const email = entry.email;
      const username = entry.username;
      delete entry.phone;
      delete entry.email;
      const threepids = [];
      if (phone) {
        threepids.push({
          medium: "msisdn",
          address: phone,
        });
      }
      if (username) {
        threepids.push({
          medium: "msisdn",
          address: `@${username}`,
        });
      }
      if (email) {
        threepids.push({
          medium: "email",
          address: email,
        });
      }

      entry.threepids = threepids;

      // Handle import field platforms
      let settings = {
        platforms: [], // Set default value of platforms
      };
      if (entry.platforms) {
        settings['platforms'] = stringToArray(entry.platforms);
        delete entry.platforms;
      }
      entry.settings = settings;

      /* TODO record update stats (especially admin no -> yes, deactivated x -> !x, ... */
      Object.assign(userRecord, entry);
      Object.assign(userRecord, overwriteData);
      /* For these modes we will consider the ID that's in the record.
        * If the mode is "stop", we will not continue adding more records, and
        * we will offer information on what was already added and what was
        * skipped.
        *
        * If the mode is "skip", we record the record for later, but don't
        * send it to the server.
        *
        * If the mode is "update", we change fields that are reasonable to
        * update.
        *  - If the "password mode" is "true" (i.e. "use passwords from csv"):
        *    - if the record has a password
        *      - send the password along with the record
        *    - if the record has no password
        *      - generate a new password
        *  - If the "password mode" is "false"
        *    - never generate a new password to update existing users with
        */

      /* We just act as if there are no IDs in the CSV, so every user will be
        * created anew.
        * We do a simple retry loop so that an accidental hit on an existing ID
        * doesn't trip us up.
        */
      if (LOGGING) {
        logger.log(
          "will check for existence of record " + JSON.stringify(userRecord)
        );
      }
      if (!dryRun) {
        await dataProvider.import("users", { data: userRecord }).then(async res => {
          if (LOGGING) {
            logger.log(
              "OK to create record " +
              userRecord.id +
              " (" +
              userRecord.displayname +
              ")."
            );
          }
          succeededRecords.push(userRecord);
        // eslint-disable-next-line no-loop-func
        }).catch(async e => {
          if (e.body?.statusCode === 400 && e.body?.errors) {
            if (LOGGING) {
              logger.log("already existed");
            }
            if (useridMode === "update" || conflictMode === "skip") {
              let duplicates = Object.keys(e.body.errors);
              let record = Object.assign({}, userRecord);
              delete record.threepids;
              record["email"] = userRecord.threepids.find(
                i => i.medium === EUserThreePID.email
              )?.address;
              record["phone"] = userRecord.threepids.find(
                i => i.medium === EUserThreePID.phone && !i.address.startsWith('@')
              )?.address;
              record["username"] = userRecord.threepids.find(
                i => i.medium === EUserThreePID.username
              )?.address;
              skippedRecords.push({
                ...getSkippedRecord(record),
                cause: `Duplicated ${duplicates?.find((i) => i === ExistedUserField.email) ? 'email: ' + record["email"] : ''} ${duplicates?.find((i) => i === ExistedUserField.phone) ? 'phone: ' + record["phone"] : ''}`,
              });
            }
          } else {
            entriesError = e;
          }
        });
      } else {
        succeededRecords.push(userRecord);
      }
      entriesDone++;
      setProgress({ done: entriesDone, limit: data.length });
      if (entriesError) {
        setError(
          translate("import_users.error.at_entry", {
            entry: entriesDone,
            message: entriesError.body?.error || entriesError.message || entriesError,
          })
        );
        break;
      }
    }

    setProgress(null);

    return {
      skippedRecords,
      erroredRecords,
      succeededRecords,
      totalRecordCount: entriesCount,
      changeStats,
      wasDryRun: dryRun,
    };
  };

  const downloadSkippedRecords = () => {
    const element = document.createElement("a");
    logger.log(skippedRecords);
    const file = new Blob([skippedRecords], {
      type: "text/comma-separated-values",
    });
    element.href = URL.createObjectURL(file);
    element.download = "skippedRecords.csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const getSkippedRecord = (record) => {
    return {
      phone: record["phone"],
      email: record["email"],
      displayname: record["displayname"],
      platforms: record["platforms"],
      cause: record["cause"],
    }
  }
  const onConflictModeChanged = async e => {
    if (progress !== null) {
      return;
    }

    const value = e.target.value;
    setConflictMode(value);
  };

  // eslint-disable-next-line
  const onPasswordModeChange = e => {
    if (progress !== null) {
      return;
    }

    setPasswordMode(e.target.checked);
  };

  // eslint-disable-next-line
  const onUseridModeChanged = async e => {
    if (progress !== null) {
      return;
    }

    const value = e.target.value;
    setUseridMode(value);
  };

  const onDryRunModeChanged = ev => {
    if (progress !== null) {
      return;
    }
    setDryRun(ev.target.checked);
  };

  // render individual small components

  const statsCards = stats &&
    !importResults && [
      <CardHeader
        title={translate(
          "import_users.cards.importstats.users_total",
          stats.total
        )}
      />,
    ];

  // eslint-disable-next-line
  let conflictCards = stats && !importResults && (
    <Container>
      <CardHeader title={translate("import_users.cards.conflicts.header")} />
      <CardContent>
        <div>
          <NativeSelect
            onChange={onConflictModeChanged}
            value={conflictMode}
            enabled={(progress !== null).toString()}
          >
            <TranslatableOption
              value="stop"
              text="import_users.cards.conflicts.mode.stop"
            />
            <TranslatableOption
              value="skip"
              text="import_users.cards.conflicts.mode.skip"
            />
          </NativeSelect>
        </div>
      </CardContent>
    </Container>
  );

  let errorCards = error && (
    <CardContent color={"error"}>
      <CardContent>
        {(Array.isArray(error) ? error : [error]).map(e => (
          <p
            style={{
              color: "red",
            }}
          >
            {e}
          </p>
        ))}
      </CardContent>
    </CardContent>
  );

  let uploadCard = !importResults && (
    <CardContent>
      <CardHeader title={translate("import_users.cards.upload.header")} />
      <CardContent>
        {translate("import_users.cards.upload.explanation")}
        <a href="./data/example.csv">example.csv</a>
        <br />
        <p>
          <strong style={{color: 'red', textTransform: 'uppercase'}}>
            {translate("import_users.note.title")}: &nbsp;
          </strong>
          {translate("import_users.note.examplePhone", { phoneNumber: '0949196969'})}
        </p>
        <p>
          {translate("import_users.note.inputPhoneExplanation", { phoneNumber: '84949196969'})}
        </p>
        <br />
        <input
          type="file"
          onChange={onFileChange}
          enabled={(progress !== null).toString()}
          accept={".csv"}
        />
      </CardContent>
    </CardContent>
  );

  let resultsCard = importResults && (
    <CardContent>
      <CardContent>
        <div>
          <Card>
            <CardHeader
              title={translate("import_users.cards.results.header")}
            />
            <CardContent>
              <CardHeader
                title={translate(
                  "import_users.cards.results.total",
                  importResults.totalRecordCount
                )}
              />
              <CardHeader
                title={translate(
                  "import_users.cards.results.successful",
                  importResults.succeededRecords.length
                )}
              />
            </CardContent>
          </Card>
          <br />

          {importResults.skippedRecords.length
            ? [
                <Card>
                  <CardHeader
                    title={translate(
                      "import_users.cards.results.skipped",
                      importResults.skippedRecords.length
                    )}
                  />
                  <CardContent>
                    <Button
                      onClick={downloadSkippedRecords}
                      variant="contained"
                      color="primary"
                      size="small"
                    >
                      {translate("import_users.cards.results.download_skipped")}
                    </Button>
                  </CardContent>
                </Card>,
              ]
            : ""}
          {importResults.erroredRecords.length
            ? [
                translate(
                  "import_users.cards.results.skipped",
                  importResults.erroredRecords.length
                ),
                <br />,
              ]
            : ""}
          <br />
          {importResults.wasDryRun && [
            translate("import_users.cards.results.simulated_only"),
            <br />,
          ]}
        </div>
      </CardContent>
    </CardContent>
  );

  let startImportCard =
    !values || values.length === 0 || importResults ? undefined : (
      <CardContent>
        {statsCards}
        <CardActions>
          <FormControlLabel
            control={
              <Checkbox
                checked={dryRun}
                onChange={onDryRunModeChanged}
                enabled={(progress !== null).toString()}
              />
            }
            label={translate("import_users.cards.startImport.simulate_only")}
          />
          <ReactAdminButton
            size="large"
            onClick={runImport}
            enabled={(progress !== null).toString()}
            color="primary"
            component="span"
            variant="contained"
            label={translate("import_users.cards.startImport.run_import")}
          >
            <GetAppIcon
              style={{ transform: "rotate(180deg)", fontSize: "20" }}
            />
          </ReactAdminButton>
          {progress !== null ? (
            <div>
              {progress.done} of {progress.limit} done
            </div>
          ) : null}
        </CardActions>
      </CardContent>
    );

  let allCards = [];
  if (uploadCard) allCards.push(uploadCard);
  if (errorCards) allCards.push(errorCards);
  if (startImportCard) allCards.push(startImportCard);
  if (resultsCard) allCards.push(resultsCard);

  let cardContainer = <Card>{allCards}</Card>;

  return [
    <Title defaultTitle={translate("import_users.title")} />,
    cardContainer,
  ];
};

export const ImportFeature = FilePicker;
