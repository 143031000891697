import React, { useState, useEffect } from "react";
import {
  maxLength,
  required,
  TextInput,
  FormTab,
  useNotify,
  useTranslate,
  BooleanInput,
  ImageInput,
  FormDataConsumer,
  Confirm,
  useRedirect,
  SelectInput,
} from "react-admin";
import { Grid, Box } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import BotIcon from "../../../customIcons/BotIcon";
import { Button, ThemeProvider } from "@mui/material";
import muiTheme from "../../../helper/muiTheme";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Autorenew from "@material-ui/icons/Autorenew";
import HelpOutline from "@material-ui/icons/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import { formatDate, truncate, validateFileType } from "../../../helper/utils";
import botProvider from "../../../synapse/botProvider";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordion from "@mui/material/Accordion";
import { styled } from "@mui/material/styles";
import { useForm } from "react-final-form";
import { mapStateToProps } from "../../../helper/bot";
import { useDispatch, connect } from "react-redux";
import * as botContainersSaga from "../../../sagas/botContainersSaga";

const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

export const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    expandIcon={<HelpOutline style={{ fontSize: "16" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-content": {
    marginLeft: "3px",
    marginTop: 0,
    marginBottom: 0,
    padding: 0,
    color: "#002884",
    "&:hover": {
      opacity: 0.8,
    },
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
  padding: theme.spacing(0),
  paddingLeft: "15px",
  minHeight: 20,
  width: "50%",
  marginTop: "-15px",
  "&:hover:active::after": {
    backgroundColor: "transparent",
  },
  "&:hover .MuiAccordionSummary-expandIconWrapper": {
    opacity: 0.8,
  },
  ".MuiAccordionSummary-expandIconWrapper": {
    color: "#002884",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(2),
  border: "2px solid #c8c8c8",
}));

export const useStyles = makeStyles({
  avatar: {
    height: "120px",
    width: "120px",
    float: "right",
    cursor: "pointer",
  },
  imagePreview: {
    height: "120px",
    width: "120px",
    float: "right",
    borderRadius: "60px",
    cursor: "pointer",
  },
  copyClipboardBox: {
    display: "flex",
  },
  copyClipboard: {
    cursor: "pointer",
    height: "30px",
    margin: "0 10px",
  },
  randomPassword: {
    cursor: "pointer",
    height: "30px",
  },
  menuPaper: {
    maxHeight: "200px",
  },
  actionBox: {
    height: "65px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  blurText: {
    "& input": {
      color: "transparent",
      textShadow: "3px 0px 7px #000",
    }
  },
  spin: {
    animationName: "$spin",
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
});

const validateName = value => {
  if (/^\s*$/.test(value?.trim())) {
    return "resources.bots.validations.invalid_name";
  }
  return undefined;
};

const validateDescription = value => {
  if (/^\s*$/.test(value?.trim())) {
    return "resources.bots.validations.invalid_description";
  }
};

const BotProfileConnect = props => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const notify = useNotify();
  const translate = useTranslate();
  const form = useForm();
  const redirect = useRedirect();

  const [rootImage, setRootImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const [botToken, setBotToken] = useState("");
  const [shareKey, setShareKey] = useState("");
  const [imageRequired, setImageRequired] = useState(false);
  const [isCopiedToken, setIsCopiedToken] = useState(false);
  const [isCopiedKey, setIsCopiedKey] = useState(false);
  const [copyText, setCopyText] = useState("Copied");
  const [generatingBotToken, setGeneratingBotToken] = useState(false);
  const [generatingShareKey, setGeneratingShareKey] = useState(false);
  const [confirm, setConfirm] = React.useState({
    isOpen: false,
    title: "",
    content: "",
    type: "",
  });

  useEffect(() => {
    setRootImage(props.bot.avatarSrc);
    setBotToken(
      props.bot.tokens &&
        props.bot.tokens.length > 0 &&
        props.bot.tokens[0]?.botToken
    );
  }, [props.bot]);

  const onFileChange = e => {
    const file = e.target.files[0];
    if (file) {
      const maxSize = 5 * 1024 * 1024; // 5MB

      if (!validateFileType(file.type, ["png", "jpg", "jpeg"])) {
        notify("resources.bots.notifications.image_invalid_type", {
          type: "error",
        });
        return;
      }

      if (file.size > maxSize) {
        notify("resources.bots.notifications.image_max_size", {
          type: "error",
        });
        return;
      }

      const normalImagePicker = document.getElementById("file-choose");
      form.change("avatarSrc", normalImagePicker.files);

      const reader = new FileReader();

      reader.onload = event => {
        setImageUrl(event.target.result);
        props.setImageFile(file);
        setImageRequired(false);
      };

      reader.readAsDataURL(file);
    }
  };

  function onGenerate(event, type) {
    event.preventDefault();
    if (type === "TOKEN") {
      setConfirm({
        isOpen: true,
        title: translate("resources.bots.confirm.title_change_bot_token"),
        content: translate("resources.bots.confirm.content_change_bot_token"),
        type: "TOKEN",
      });
    }

    if (type === "SHARE_KEY") {
      generateShareKey();
    }
  }

  const onCopy = (event, type) => {
    event.preventDefault();
    if (type === "TOKEN") {
      setCopyText(
        botToken
          ? translate("resources.users.action.copied")
          : translate("resources.users.action.empty")
      );
      setIsCopiedToken(true);
      setTimeout(() => {
        setIsCopiedToken(false);
      }, 1000);
    }

    if (type === "SHARE_KEY") {
      setCopyText(
        shareKey
          ? translate("resources.users.action.copied")
          : translate("resources.users.action.empty")
      );
      setIsCopiedKey(true);
      setTimeout(() => {
        setIsCopiedKey(false);
      }, 1000);
    }
  };

  const generateBotToken = () => {
    setGeneratingBotToken(true);
    botProvider
      .generateBotToken(props.bot.id)
      .then(({ json }) => {
        setBotToken(json.botToken);
        props.setBotToken(json.botToken);
        notify("resources.bots.notifications.created_bot_token", {
          type: "success",
        });
      })
      .catch(() => {
        notify("resources.bots.notifications.generate_failed", {
          type: "error",
        });
      })
      .finally(() => {
        setGeneratingBotToken(false);
      });
  };

  const generateShareKey = () => {
    setGeneratingShareKey(true);
    botProvider
      .generateShareKey(props.bot.id)
      .then(({ json }) => {
        setShareKey(json.key);
        notify("resources.bots.notifications.created_share_key", {
          type: "success",
        });
      })
      .catch(() => {
        notify("resources.bots.notifications.generate_failed", {
          type: "error",
        });
      })
      .finally(() => {
        setGeneratingShareKey(false);
      });
  };

  const handleChange = panel => (event, newExpanded) => {
    redirect('edit', '/bots/' + props.bot.botId, 'help');
  };

  const handleConfirm = () => {
    if (confirm && confirm.type) {
      if (confirm.type === "TOKEN") {
        generateBotToken();
        handleDialogClose();
      }
    }
  };

  const handleDialogClose = () =>
    setConfirm({
      isOpen: false,
      title: "",
      content: "",
      type: "",
  });

    
  useEffect(() => {
    if (!props.botContainers?.data.length) {
        dispatch({ type: botContainersSaga.FETCH_BOT_CONTAINERS_SAGA });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormTab {...props} label="resources.bots.tabs.profile" icon={<BotIcon />}>
      {props.fetchBotDetail ? (
        <div
          style={{
            margin: "75px auto 100px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={50} thickness={2} />
        </div>
      ) : (
        <Grid
          container
          width="100%"
          spacing={2}
          display="flex"
          justifyContent="space-between"
        >
          <Grid item xs={8} md={6} lg={5}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <TextInput
                source="id"
                variant="outlined"
                disabled={true}
                label="resources.bots.fields.bot_id"
              />
              <TextInput
                source="name"
                variant="outlined"
                validate={[
                  required("resources.bots.validations.name_required"),
                  maxLength(
                    64,
                    "resources.bots.validations.invalid_length_name"
                  ),
                  validateName,
                ]}
                label="resources.bots.fields.displayname"
              />
              <TextInput
                source="description"
                variant="outlined"
                multiline
                minRows={5}
                validate={[
                  required("resources.bots.validations.description_required"),
                  maxLength(
                    255,
                    "resources.bots.validations.invalid_length_description"
                  ),
                  validateDescription,
                ]}
                label="resources.bots.fields.description"
              />
              <SelectInput
                variant="outlined"
                source="containerId"
                choices={props.botContainers?.data}
                size="medium"
                loading={props.botContainers?.loading}
                label="resources.bots.fields.container"
                validate={[
                  required("resources.bots.validations.container_required"),
                ]}
              />
              <BooleanInput
                source="isEnabled"
                label="resources.bots.fields.status"
              />
              <FormDataConsumer>
                {({ formData }) =>
                  formData.isEnabled ? (
                    <div className={classes.copyClipboardBox}>
                      <FormControl fullWidth variant="standard">
                        <InputLabel htmlFor="component-disabled">
                          {translate("resources.bots.fields.bot_token")}
                        </InputLabel>
                        <Input
                          readOnly={true}
                          value={botToken ? truncate(botToken, 30) : translate("resources.bots.fields.no_token_found")}
                          disableUnderline={true}
                          className={[generatingBotToken && classes.blurText]}
                        />
                      </FormControl>
                      <div className={classes.actionBox}>
                        {!generatingBotToken && (
                          <CopyToClipboard text={botToken}>
                            <span
                              className={classes.copyClipboard}
                              onClick={event => onCopy(event, "TOKEN")}
                              onKeyDown={() => {}}
                            >
                              <Tooltip
                                title={copyText}
                                open={isCopiedToken}
                                placement="top"
                              >
                                <FileCopyIcon style={{ fontSize: "23" }} />
                              </Tooltip>
                            </span>
                          </CopyToClipboard>
                        )}
                        <span
                          className={classes.randomPassword}
                          onClick={event => onGenerate(event, "TOKEN")}
                          onKeyDown={() => {}}
                        >
                          <Tooltip
                            title={translate("resources.users.action.generate")}
                            placement="top"
                          >
                            <Autorenew
                              className={[generatingBotToken && classes.spin]}
                              style={{
                                fontSize: "25",
                                animationDuration: `1s`,
                                animationDelay: `0.5s`,
                                animationIterationCount: "infinite",
                              }}
                            />
                          </Tooltip>
                        </span>
                      </div>
                    </div>
                  ) : null
                }
              </FormDataConsumer>
            </Box>
          </Grid>

          <Grid
            item
            xs={4}
            md={6}
            lg={7}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                width: "120px",
              }}
            >
              <label for="file-choose">
                <input
                  id="file-choose"
                  type="file"
                  hidden
                  onChange={onFileChange}
                  accept={".png, .jpg, .jpeg"}
                />

                {!imageUrl && (
                  <img
                    className={classes.imagePreview}
                    src={rootImage}
                    alt="Root bot review"
                    style={imageRequired ? { border: "1px solid #d32f2f" } : {}}
                  />
                )}
                {imageUrl && (
                  <img
                    className={classes.imagePreview}
                    src={imageUrl}
                    alt="Bot review"
                  />
                )}
              </label>
              <ThemeProvider theme={muiTheme}>
                <Button
                  color={imageRequired ? "error" : "primary"}
                  sx={{ marginTop: "5px" }}
                  onClick={() => document.getElementById("file-choose").click()}
                >
                  {translate("resources.bots.actions.upload_edit")}
                </Button>
              </ThemeProvider>
              <Box sx={{ display: "none" }}>
                <ImageInput
                  id="imageAvatar"
                  source="avatarSrc"
                  accept="image/*"
                  maxSize="5000000"
                ></ImageInput>
              </Box>
            </Box>
          </Grid>
          <FormDataConsumer>
            {({ formData }) =>
              formData.isEnabled ? (
                <Grid
                  container
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                  sx={{ paddingTop: "0px" }}
                >
                  <Grid item xs={12} md={8} lg={8} sx={{ paddingTop: "0px" }}>
                    <Accordion
                      onChange={handleChange("howToUse")}
                      hidden={!botToken}
                    >
                      <AccordionSummary
                        aria-controls="howToUse-content"
                        id="howToUse-header"
                      >
                        <Typography sx={{ fontSize: "0.9rem" }}>
                          {translate("resources.bots.fields.how_to_use")}
                        </Typography>
                      </AccordionSummary>
                      {/* <AccordionDetails>
                        <HowToUseBotToken botToken={botToken} />
                      </AccordionDetails> */}
                    </Accordion>
                  </Grid>
                </Grid>
              ) : null
            }
          </FormDataConsumer>
          <Grid
            item
            xs={8}
            md={6}
            lg={5}
            sx={{
              paddingTop: "0px !important",
              marginTop: shareKey ? "20px" : 0,
            }}
          >
            <FormDataConsumer>
              {({ formData }) =>
                formData.isEnabled ? (
                  <div className={classes.copyClipboardBox}>
                    <FormControl
                      disabled={!shareKey}
                      fullWidth
                      variant="standard"
                    >
                      <InputLabel htmlFor="component-disabled">
                        {translate("resources.bots.fields.share_key")}
                      </InputLabel>
                      <Input
                        readOnly={true}
                        value={shareKey}
                        disableUnderline={true}
                        className={[ generatingShareKey && classes.blurText ]}
                      />
                    </FormControl>
                    <div className={classes.actionBox}>
                      {shareKey && !generatingShareKey && (
                        <CopyToClipboard text={shareKey}>
                          <span
                            className={classes.copyClipboard}
                            onClick={event => onCopy(event, "SHARE_KEY")}
                            onKeyDown={() => {}}
                          >
                            <Tooltip
                              title={copyText}
                              open={isCopiedKey}
                              placement="top"
                            >
                              <FileCopyIcon style={{ fontSize: "23" }} />
                            </Tooltip>
                          </span>
                        </CopyToClipboard>
                      )}
                      <span
                        className={classes.randomPassword}
                        onClick={event => onGenerate(event, "SHARE_KEY")}
                        onKeyDown={() => {}}
                      >
                        <Tooltip
                          title={translate("resources.users.action.generate")}
                          placement="top"
                        >
                          <Autorenew
                            className={[generatingShareKey && classes.spin]}
                            style={{
                              fontSize: "25",
                              animationDuration: `1s`,
                              animationDelay: `0.5s`,
                              animationIterationCount: "infinite",
                            }}
                          />
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                ) : null
              }
            </FormDataConsumer>

            <FormControl fullWidth variant="standard">
              <InputLabel htmlFor="component-disabled">
                {translate("resources.bots.fields.createdAt")}
              </InputLabel>
              <Input
                readOnly={true}
                value={formatDate(props.bot?.createdAt)}
                disableUnderline={true}
              />
            </FormControl>
          </Grid>
          <Confirm
            isOpen={confirm.isOpen}
            title={confirm.title}
            content={confirm.content}
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
          />
        </Grid>
      )}
    </FormTab>
  );
};

export const BotProfile = connect(mapStateToProps)(BotProfileConnect);
