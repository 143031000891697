import DvrIcon from "@material-ui/icons/Dvr";
import {
    FormTab,
    useTranslate,
} from "react-admin";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  label: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  valueField: {
    padding: '8px 0 4px',
    marginLeft: '15px',
  },
  messageErr: {
    padding: '20px',
    color: 'red'
  }
});

const LabelField = ({ children }) => {
  return (
    <div
      style={{
        color: 'rgba(0, 0, 0, 0.54)',
        padding: '0',
        fontSize: '12px',
        marginTop: '0px',
      }}
    >
      <label>{ children }</label>
    </div>
  );
};

function getStatusValue(status, options) {
    if (status !== 'unknown') {
      return status ? options.truthy : options.falsy;
    }
    return status;
}

const StatusField = (props) => {
  const color = props.success !== 'unknown' ?
    (props.success ? '#2cbf52' : 'rgb(244, 67, 54)')
    : '#ccc';
  return (
    <div
      className={props.className}
      style={{ color: color }}
    >
      { props.children }
    </div>
  );
}

const NumberField = (props) => {
  function formatNumber(value) {
    return new Intl.NumberFormat().format(value);
  }

  return (
    <div className={props.className}>{ formatNumber(props.value) }</div>
  )
}

const BotQueue = ({ queueInfo, ...props}) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <FormTab {...props} label="Bot Queue" icon={<DvrIcon />}>
            {
                queueInfo?.messageErr ? <div className={classes.messageErr}>
                    {translate(queueInfo?.messageErr)}
                </div> :
                <div>
                    <div className={classes.label}>
                        <LabelField>
                            {translate("resources.bots.fields.queue_name")}:
                        </LabelField>
                        <div className={classes.valueField}>{ queueInfo?.queueName }</div>
                    </div>
                    <div className={classes.label}>
                        <LabelField>
                            {translate("resources.bots.fields.queue_is_ready")}:
                        </LabelField>
                        <StatusField success={queueInfo?.isReady} className={classes.valueField}>
                            {
                            translate(`resources.bots.queue.status.${
                                getStatusValue(queueInfo?.isReady, { truthy: 'ready', falsy: 'not_ready'})
                            }`)
                            }
                        </StatusField>
                    </div>
                    <div className={classes.label}>
                        <LabelField>{translate("resources.bots.fields.queue_count")}:</LabelField>
                        <NumberField className={classes.valueField} value={ queueInfo?.messageCount ?? 0 }/>
                    </div>
                    <div className={classes.label}>
                        <LabelField>{translate("resources.bots.fields.queue_consumer")}:</LabelField>
                        <NumberField className={classes.valueField} value={ queueInfo?.consumerCount ?? 0 }/>
                    </div>
                </div>
            }
        </FormTab>
    );
};

export default BotQueue;
