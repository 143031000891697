import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { StyledTextField } from './FormController';
import { checkValidation } from "./FormController";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    sourceContainer: {
        display: 'flex',
        padding: '10px',
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '4px',
        flexDirection: 'column',
        backgroundColor: '#ffffff'
    },
});

const initValidations = {
    limit_messageErr: '',
    size_messageErr: '',
}


const FileFormController = ({component, index, ...props}) => {
    const translate = useTranslate();
    const classes = useStyles();

    const [id, setId] = useState('');
    const [validations, setValidations] = useState(initValidations);

    useEffect(() => {
        setId(component.id);
        if (!id) {
            setValidations({
                ...validations,
                limit_messageErr: handleMessageErr(component.limit, 'limit'),
                size_messageErr: handleMessageErr(component.size, 'size'),
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (id && id !== component.id) {
            setId(component.id);
            setValidations({
                ...validations,
                limit_messageErr: handleMessageErr(component.limit, 'limit'),
                size_messageErr: handleMessageErr(component.size, 'size'),
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [component, id]);

    const onFileTextChange = (e) => {
        const { name, value } = e.target;

        const inputValidation = {
            ...validations,
            [`${name}_messageErr`]: handleMessageErr(value, name),
        };
        const isInValid = checkValidation(inputValidation);
        setValidations(inputValidation);

        props.onChildFormChange({
            ...component,
            [name]: value,
        }, isInValid);
    }

    const onFileSourceChange = (e) => {
        const { name, checked } = e.target;
        let optionChange;
        if (checked) {
            optionChange = [...component.sources, name];
        } else {
            if (component.sources?.length === 1) {
                return;
            }
            optionChange = component.sources.filter(e => e !== name);
        }

        const isInValid = checkValidation(validations);
        props.onChildFormChange({
            ...component,
            'sources': optionChange,
        }, isInValid);
    };

    const handleMessageErr = (value, field) => {
        if (!value) {
            return `resources.bots.validations.required`;
        }
        if (!(/^(?:[1-9]|10)$/.test(value)) && field === 'limit') {
            return `resources.bots.validations.invalid_file_limit`;
        }
        if (!(/^(?:[1-9]|[1-9][0-9]|100)$/.test(value)) && field === 'size') {
            return `resources.bots.validations.invalid_file_size`;
        }
        return '';
    }

    return (
        <div className={classes.container}>
            <StyledTextField
                id="limit"
                label={translate(`resources.bots.fields.testform.file_limit`)}
                name="limit"
                value={component.limit}
                onChange={onFileTextChange}
                onClick={onFileTextChange}
                variant="outlined"
                size="small"
                type="number"
                required
                error={validations.limit_messageErr}
                helperText={validations?.limit_messageErr ? translate(validations?.limit_messageErr) : ""}
            />
            <StyledTextField
                id="size"
                label={translate(`resources.bots.fields.testform.file_max_file_size`)}
                name="size"
                value={component.size}
                onChange={onFileTextChange}
                onClick={onFileTextChange}
                variant="outlined"
                size="small"
                type="number"
                required
                error={validations.size_messageErr}
                helperText={validations?.size_messageErr ? translate(validations?.size_messageErr) : ""}
            />
            <div className={classes.sourceContainer}>
                {translate(`resources.bots.fields.testform.file_sources`)}
                <FormControlLabel
                    control={
                        <Checkbox
                            name='camera'
                            checked={component.sources.includes('camera') || false}
                            onChange={onFileSourceChange}
                        />
                    }
                    label={translate(`resources.bots.fields.testform.file_camera`)}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            name='media'
                            checked={component.sources.includes('media') || false}
                            onChange={onFileSourceChange}
                        />
                    }
                    label={translate(`resources.bots.fields.testform.file_media`)}
                />
            </div>
        </div>
    );
}

export default FileFormController;