import { useTranslate } from "react-admin";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import React from "react";
import { Box, Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import CryptoJS from "crypto-js";
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@mui/material/IconButton';
import { EBotSetting } from "../../../settings/BotSettings";
import { truncate } from "../../../../helper/utils";

const default_allowed_file_types = ['image/*', 'application/pdf'];
const default_file_size = 10 * 1024 * 1024; // 10MB

const useStyles = makeStyles({
    attachment: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    label: {
        fontWeight: 'bold',
        marginBottom: '10px',
    },
    errorText: {
        color:'red',
        fontSize: '12px',
        maxWidth: '50%',
    },
});

export const AttachFile = ({...props}) => {
    const classes = useStyles();
    const translate = useTranslate();

    const handleFileUpload = (e) => {
        if (!e.target.files || !e.target.files?.length) {
          return;
        }
        const file = e.target.files[0];
        const fileSize = +props.settings?.[EBotSetting.BOT_FORM_MAX_REQUEST_FILE_SIZE] ?? default_file_size;
        if (file?.size > fileSize) {
            props.onFileAttach({
                file: '',
                fileBuffer: '',
                filename: '',
                fileHelperText: "resources.bots.fields.testform.file_size_limit_exceeded",
            }, props.index);
            return;
        }
        if (file?.name.length > 64) {
            props.onFileAttach({
                file: '',
                fileBuffer: '',
                filename: '',
                fileHelperText: "resources.bots.fields.testform.file_name_limit",
            }, props.index);
            return;
        }
        const reader = new FileReader();
        reader.onload = event => {
            const buffer = event.target.result;
            const wordArray = CryptoJS.lib.WordArray.create(buffer);
            props.onFileAttach({
                file,
                fileBuffer: wordArray,
                filename: file?.name,
                fileHelperText: '',
            }, props.index);
        };
        reader.readAsArrayBuffer(file);
    };

    const onRemoveFile = () => {
        props.onFileAttach({
            file: '',
            fileBuffer: '',
            filename: '',
            fileHelperText: '',
        }, props.index);
    };

    return (
        <div>
            <div className={classes.label}>
                {props.data.label}
            </div>
            <div className={classes.attachment}>
                <div style={{display: 'flex', marginTop: '5px'}}>
                    <div>
                        <Button
                            component="label"
                            variant="outlined"
                            startIcon={<AttachFileIcon />}
                        >
                            {translate("resources.bots.fields.testform.choose_file")}
                            <input
                                type="file"
                                hidden
                                onChange={handleFileUpload}
                                accept={props.settings?.[EBotSetting.BOT_FORM_REQUEST_FILE_TYPES] ?? default_allowed_file_types}
                            />
                        </Button>
                        <div className={classes.errorText} style={{ maxWidth: '100%', marginTop: '5px' }}>
                            {translate(props.data.fileHelperText)}
                        </div>
                    </div>
                    <Box sx={{margin: '10px 0px 0px 10px'}}>
                        {truncate(props.data.filename, 30)}
                    </Box>
                </div>
                {
                    props.data.filename && <IconButton onClick={onRemoveFile}>
                        <CancelIcon />
                    </IconButton> 
                }                
            </div>
        </div>        
    );
};
