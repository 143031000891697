import React, { useState, useEffect } from "react";
import {
  Create,
  maxLength,
  required,
  SimpleForm,
  TextInput,
  useNotify,
  useTranslate,
  useRedirect,
  SelectInput,
} from "react-admin";
import { styled } from "@mui/material/styles";
import { Grid, Box } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { Button, ThemeProvider } from "@mui/material";
import { useDispatch, connect } from "react-redux";
import muiTheme from "../../helper/muiTheme";
import botProvider from "../../synapse/botProvider";
import ToolbarSaveButton from "../common/ToolbarSaveButton";
import { validateFileType } from "../../helper/utils";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import * as botContainersSaga from "../../sagas/botContainersSaga";

const ErrorTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f44336",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
  ".MuiTooltip-arrow": {
    color: "#f44336",
  }
}));

const useStyles = makeStyles({
  avatar: {
    height: "120px",
    width: "120px",
    float: "right",
    cursor: "pointer",
  },
  imagePreview: {
    height: "120px",
    width: "120px",
    float: "right",
    borderRadius: "60px",
    cursor: "pointer",
  },
});

const validateName = value => {
  if (/^\s*$/.test(value?.trim())) {
    return "resources.bots.validations.invalid_name";
  }
  return undefined;
};

const validateDescription = value => {
  if (/^\s*$/.test(value?.trim())) {
    return "resources.bots.validations.invalid_description";
  }
};

function mapStateToProps(state) {
  return {
    botContainers: state.botContainers,
  };
}

const BotCreateConnect = props => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const notify = useNotify();
  const translate = useTranslate();
  const redirect = useRedirect();

  const [imageUrl, setImageUrl] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [imageRequired, setImageRequired] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!props.botContainers?.data.length) {
        dispatch({ type: botContainersSaga.FETCH_BOT_CONTAINERS_SAGA });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFileChange = e => {
    const file = e.target.files[0];

    if (file) {
      const maxSize = 5 * 1024 * 1024; // 5MB

      if (!validateFileType(file.type, ["png", "jpg", "jpeg"])) {
        notify("resources.bots.notifications.image_invalid_type", {
          type: "error",
        });
        return;
      }

      if (file.size > maxSize) {
        notify("resources.bots.notifications.image_max_size", {
          type: "error",
        });
        return;
      }

      const reader = new FileReader();

      reader.onload = event => {
        setImageUrl(event.target.result);
        setImageFile(file);
        setImageRequired(false);
      };

      reader.readAsDataURL(file);
    }
  };

  const save = values => {
    if (!imageFile) {
      notify("resources.bots.validations.avatar_required", { type: "error" });
      setImageRequired(true);
    } else {
      setLoading(true);
      const data = {
        avatar: imageFile,
        name: values.displayname?.trim(),
        description: values.description?.trim(),
        containerId: values.containerId,
      };
      botProvider
        .createBot(data)
        .then(res => {
          setImageUrl("");
          setImageFile("");
          redirect("edit", "/bots", res["json"]["id"]);
          notify("resources.bots.notifications.created", { type: "success" });
        })
        .catch(error => {
          notify("resources.bots.notifications.save_failed", { type: "error" });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Create {...props}>
      <SimpleForm
        save={save}
        toolbar={<ToolbarSaveButton {...props} loading={loading} />}
      >
        <Grid
          container
          width="100%"
          spacing={2}
          display="flex"
          justifyContent="space-between"
        >
          <Grid item xs={8} md={6} lg={5}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <TextInput
                source="displayname"
                variant="outlined"
                validate={[
                  required("resources.bots.validations.name_required"),
                  maxLength(
                    64,
                    "resources.bots.validations.invalid_length_name"
                  ),
                  validateName,
                ]}
              />
              <TextInput
                source="description"
                variant="outlined"
                multiline
                minRows={5}
                validate={[
                  required("resources.bots.validations.description_required"),
                  maxLength(
                    255,
                    "resources.bots.validations.invalid_length_description"
                  ),
                  validateDescription,
                ]}
              />
              <SelectInput
                variant="outlined"
                source="containerId"
                choices={props.botContainers?.data}
                size="medium"
                loading={props.botContainers?.loading}
                label="resources.bots.fields.container"
                validate={[
                  required("resources.bots.validations.container_required"),
                ]}
              />
            </Box>
          </Grid>

          <Grid
            item
            xs={4}
            md={6}
            lg={7}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <ErrorTooltip
              placement="left-start"
              arrow
              open={imageRequired}
              title={translate("resources.bots.validations.avatar_required")}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  width: "120px",
                }}
              >
                <label for="file-choose">
                  <input
                    id="file-choose"
                    type="file"
                    hidden
                    onChange={onFileChange}
                    accept={".png, .jpg, .jpeg"}
                  />

                  {!imageUrl && (
                    <Avatar
                      className={classes.avatar}
                      style={
                        imageRequired ? { border: "1px solid #f44336" } : {}
                      }
                    />
                  )}
                  {imageUrl && (
                    <img
                      className={classes.imagePreview}
                      src={imageUrl}
                      alt="Bot review"
                    />
                  )}
                </label>
                <ThemeProvider theme={muiTheme}>
                  <Button
                    color={imageRequired ? "error" : "primary"}
                    sx={{ marginTop: "5px" }}
                    onClick={() =>
                      document.getElementById("file-choose").click()
                    }
                  >
                    {translate(
                      imageUrl
                        ? "resources.bots.actions.upload_edit"
                        : "resources.bots.actions.upload"
                    )}
                  </Button>
                </ThemeProvider>
              </Box>
            </ErrorTooltip>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export const BotCreate = connect(mapStateToProps)(BotCreateConnect);
