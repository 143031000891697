import React from "react";
import { cloneElement } from "react";
import {
  Datagrid,
  Filter,
  List,
  NumberField,
  Pagination,
  sanitizeListRestProps,
  SearchInput,
  TextField,
  TopToolbar,
  useListContext,
} from "react-admin";

const ListActions = props => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
    </TopToolbar>
  );
};

const UserDeviceStatsPagination = props => (
  <Pagination {...props} rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} />
);

const UserDeviceStatsFilter = props => (
  <Filter {...props}>
    <SearchInput source="search_term" alwaysOn variant="outlined" />
  </Filter>
);

export const UserDeviceStatsList = props => {
  return (
    <List
      {...props}
      actions={<ListActions />}
      filters={<UserDeviceStatsFilter />}
      pagination={<UserDeviceStatsPagination />}
      perPage={25}
      sort={{ field: "device_count", order: "DESC" }}
      bulkActionButtons={false}
    >
      <Datagrid rowClick={(id, basePath, record) => "/users/" + id + "/devices"}>
        <TextField source="user_id" label="resources.users.fields.id" />
        <TextField
          source="displayname"
          label="resources.users.fields.displayname"
        />
        <NumberField source="device_count" />
      </Datagrid>
    </List>
  );
};
