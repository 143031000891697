import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import React, { useEffect, useState } from "react";
import { useTranslate } from "react-admin";
import './SyntaxFormatMessage.css';
import { markdown } from "../../../../utils/markdown";
import muiTheme from "../../../../helper/muiTheme";

const guides = [
  // { code: `# Heading level 1` },
  // { code: `## Heading level 2` },
  // { code: `### Heading level 3` },
  // { code: `#### Heading level 4` },
  // { code: `##### Heading level 5` },
  // { code: `###### Heading level 6` },
  { code: `I just love **bold text**` },
  { code: `I just love *italic text*` },
  // { code: `I just love __bold text__` },
  // { code: `I just love _italic text_` },
  // { code: `1. First item\n1. Second item\n1. Third item` },
  // { code: `- First item\n- Second item\n- Third item` },
  // { code: `+ First item\n+ Second item\n+ Third item` },
  { code: `Run \`sudo apt-get update\` to update` },
  { code: `My favorite website is [Google](https://google.com).` },
  { code: `~~The world is flat.~~ We now know that the world is round.` },
  { code: `Your text {primary}(is primary color).` },
  { code: `Your text {red}(is red).` },
  { code: `Your text {yellow}(is yellow).` },
  { code: `Your text {blue}(is blue).` },
  { code: `Your text {green}(is green).` },
  { code: `Your text {magenta}(is magenta).` },
  { code: `Your text {cyan}(is cyan).` },
  { code: `Your text {#ccc}(is custom color with hex #ccc).` },
];

const showMoreStyle = {
  textAlign: 'center',
  cursor: 'pointer',
  userSelect: 'none',
};

const COMPACT_SIZE = 5;

const SyntaxFormatMessage = () => {
  const translate = useTranslate();
  const [showMore, setShowMore] = useState(true);
  const [tableBodyRow, setTableBodyRow] = useState([]);


  useEffect(() => {
    const rows = showMore ? guides.slice(0, COMPACT_SIZE) : [...guides];
    const bodyRows = [];
    for (const row of rows) {
      bodyRows.push(
        <TableRow>
          <TableCell>
            <pre><code className="syntax">{row.code}</code></pre>
          </TableCell>
          <TableCell>
            <div className="output" dangerouslySetInnerHTML={{__html: markdown.render(row.code)}} />
          </TableCell>
        </TableRow>
      )
    }
    setTableBodyRow(bodyRows);
  }, [showMore]);

  const handleShowMoreOrLess = () => {
    setShowMore(!showMore);
  }

  return <TableContainer component={Paper} style={{ marginTop: "20px", marginBottom: "35px" }}>
    <Table className="Syntax-table" sx={{ minWidth: 650 }}>
      <TableHead>
        <TableRow>
          <TableCell>{translate('resources.bots.fields.syntax')}</TableCell>
          <TableCell>{translate('resources.bots.fields.rendered_output')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        { tableBodyRow }
        {
          (guides.length > COMPACT_SIZE) && <TableRow onClick={handleShowMoreOrLess}>
            <TableCell colSpan={2} align="center" style={showMoreStyle}>
              <span style={{ color: muiTheme.palette.primary.main }}>
                {translate('resources.common.show_' + (showMore ? 'more' : 'less'))}
              </span>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    </Table>
  </TableContainer>
}

export default SyntaxFormatMessage;
