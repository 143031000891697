import { Grid } from '@mui/material';
import React, { useEffect, useState } from "react";
import { FETCH_END, FETCH_START, Loading, useNotify, useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import { useDispatch } from "react-redux";
import botProvider from "../../../synapse/botProvider";
import { ErrorComponent } from "../../Error";

const useStyles = makeStyles({
  label: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  valueField: {
    padding: '8px 0 4px',
    marginLeft: '15px',
  }
});

function getStatusValue(status, options) {
  if (status !== 'unknown') {
    return status ? options.truthy : options.falsy;
  }
  return status;
}

const LabelField = ({ children }) => {
  return (
    <div
      style={{
        color: 'rgba(0, 0, 0, 0.54)',
        padding: '0',
        fontSize: '12px',
        marginTop: '0px',
      }}
    >
      <label>{ children }</label>
    </div>
  );
}

const StatusField = (props) => {
  const color = props.success !== 'unknown' ?
    (props.success ? '#2cbf52' : 'rgb(244, 67, 54)')
    : '#ccc';
  return (
    <div
      className={props.className}
      style={{ color: color }}
    >
      { props.children }
    </div>
  );
}

const NumberField = (props) => {
  function formatNumber(value) {
    return new Intl.NumberFormat().format(value);
  }

  return (
    <div className={props.className}>{ formatNumber(props.value) }</div>
  )
}

export const BotQueueSummary = (props) => {
  const classes = useStyles();
  const translate = useTranslate();

  return <Card style={{ padding: '15px' }}>
    <Grid container width="100%" spacing={2}>
      <Grid item xs={8} md={6} lg={4}>
        <div className={classes.label}>
          <LabelField>
            {translate("resources.bots.fields.queue_is_ready")}:
          </LabelField>
          <StatusField success={props.queueInfo.isReady} className={classes.valueField}>
            {
              translate(`resources.bots.queue.status.${
                getStatusValue(props.queueInfo.isReady, { truthy: 'ready', falsy: 'not_ready'})
              }`)
            }
          </StatusField>
        </div>
        <div className={classes.label}>
          <LabelField>
            {translate("resources.bots.fields.queue_is_running")}:
          </LabelField>
          <StatusField success={!props.queueInfo.isPaused} className={classes.valueField}>
            {
              translate(`resources.bots.queue.status.${
                getStatusValue(props.queueInfo.isPaused, { truthy: 'paused', falsy: 'running'})
              }`)
            }
          </StatusField>
        </div>
        <div className={classes.label}>
          <LabelField>{translate("resources.bots.fields.queue_count")}:</LabelField>
          <NumberField className={classes.valueField} value={ props.queueInfo.count }/>
        </div>
        <div className={classes.label}>
          <LabelField>{translate("resources.bots.fields.queue_active_count")}:</LabelField>
          <NumberField className={classes.valueField} value={ props.queueInfo.activeCount }/>
        </div>
        <div className={classes.label}>
          <LabelField>{translate("resources.bots.fields.queue_completed_count")}:</LabelField>
          <NumberField className={classes.valueField} value={ props.queueInfo.completedCount }/>
        </div>
        <div className={classes.label}>
          <LabelField>{translate("resources.bots.fields.queue_failed_count")}:</LabelField>
          <NumberField className={classes.valueField} value={ props.queueInfo.failedCount }/>
        </div>
        <div className={classes.label}>
          <LabelField>{translate("resources.bots.fields.queue_delayed_count")}:</LabelField>
          <NumberField className={classes.valueField} value={ props.queueInfo.delayedCount }/>
        </div>
        <div className={classes.label}>
          <LabelField>{translate("resources.bots.fields.queue_waiting_count")}:</LabelField>
          <NumberField className={classes.valueField} value={ props.queueInfo.waitingCount }/>
        </div>
        <div className={classes.label}>
          <LabelField>{translate("resources.bots.fields.queue_paused_count")}:</LabelField>
          <NumberField className={classes.valueField} value={ props.queueInfo.pausedCount }/>
        </div>
        <div className={classes.label}>
          <LabelField>{translate("resources.bots.fields.queue_repeatable_count")}:</LabelField>
          <NumberField className={classes.valueField} value={ props.queueInfo.repeatableCount }/>
        </div>
      </Grid>
    </Grid>
  </Card>
};

export const BotQueueInfo = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const notify = useNotify();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [queueInfo, setQueueInfo] = useState({
    primary: {
      isReady: 'unknown',
    },
    secondary: {
      isReady: 'unknown',
    }
  });

  useEffect(() => {
    dispatch({ type: FETCH_START });
    botProvider.getQueueInfo()
      .then((res) => {
        setQueueInfo(res.json);
      }).catch((error) => {
        notify(error.message, { type: 'error' });
        setError(error);
      }).finally(() => {
        setLoading(false);
        // eslint-disable-next-line
        dispatch({ type: FETCH_END });
    });
  }, []);

  if (loading) return <Loading />;
  if (error) return <ErrorComponent error={error} />;

  return (
    <>
      <h1>{translate('resources.bots.title.queue_primary')}</h1>
      <BotQueueSummary queueInfo={queueInfo?.primary || {}} />
      <h1>{translate('resources.bots.title.queue_secondary')}</h1>
      <BotQueueSummary queueInfo={queueInfo?.secondary || {}} />
    </>
  )
}
