import React, { useState } from "react";
import { Box } from "@mui/material";
import { env } from "../../../../env";
import { makeStyles } from "@material-ui/core/styles";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Tooltip from "@mui/material/Tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslate } from "react-admin";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import { transformStringify } from "../../../../helper/utils";

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(2),
  border: "2px solid #c8c8c8",
}));

const useStyles = makeStyles({
  container: {
    margin: 0,
    whiteSpace: "pre-wrap",
    wordBreak: "break-all",
    padding: "8px",
  },
  codeBlock: {
    background: "#ececec",
    marginBottom: "10px",
  },
  codeBlockCurl: {
    paddingRight: "10px",
    marginBottom: "0px",
  },
  noticeText: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '12px',
    fontStyle: 'italic',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '15px'
  },
});

const host = env.REACT_APP_PUBLIC_API_SERVER;
const postMethod = "POST";

const formEndpoint = botToken => `/v1/bot/${botToken}/form`;

const getBody = (curl, filename) => {
  let body = "Body:\n";
  for (const [key, value] of Object.entries(curl)) {
    if (key === 'file') {
      if (value.length) {
        body = body.concat(' ', `${key}=@"/path/to/your/files"\n`);
      }
      continue;
    }
    if (key === 'form') {
      body = body.concat(' ', `${key}="${JSON.stringify(value)}"\n`);
      continue;
    }
    body = body.concat(' ', `${key}="${value}"\n`);
  }
  return body;
}

const contentAPI = (curl, filename, signature) => {
const content = `Host URL: ${host}
Method: ${postMethod}
Endpoint: ${formEndpoint("<bot_token>")}
Headers: Content-type: multipart/form-data; boundary=something
         x-signature: ${signature}
${getBody(curl, filename)}`;
  return content;
}

const mapCurl = (curl, botToken, filename, signature) => {
  let contentCurl = `curl -X ${postMethod} ${host}${formEndpoint(botToken)} -H "x-signature: ${signature}"`;
  for (const [key, value] of Object.entries(curl)) {
    if (key === 'file') {
      if (value.length) {
        contentCurl = contentCurl.concat(' ', `-F ${key}=@"/path/to/your/files"`);
      }
      continue;
    }
    if (key === 'form') {
      contentCurl = contentCurl.concat(' ', `-F ${key}="${transformStringify(JSON.stringify(value))}"`);
      continue;
    }
    contentCurl = contentCurl.concat(' ', `-F ${key}="${transformStringify(value)}"`);
  }
  return contentCurl;
};

const CurlPreview = ({curl, botToken, filename, signature, ...props}) => {
  const classes = useStyles();
  const translate = useTranslate();

  const [isCopied, setIsCopied] = useState(false);
  const [copyText, setCopyText] = useState("Copied");

  const onCopy = (event) => {
    event.preventDefault();
    setCopyText(
      botToken
        ? translate("resources.users.action.copied")
        : translate("resources.users.action.empty")
    );
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <div>
      {
        !!curl.file?.length && <div className={classes.noticeText}>
          <span>{translate("resources.bots.fields.testform.note_replace_file_path")}</span>
          <span>Example:</span>
          <div className={classes.noticeText}>
              <span>{translate("resources.bots.fields.testform.example_path_windows")}</span>
              <span>{translate("resources.bots.fields.testform.example_path_mac")}</span>
          </div>
        </div>
      }
      <AccordionDetails>
        <Box className={classes.codeBlock}>
          <pre className={classes.container}>
            <code>{contentAPI(curl, filename, signature)}</code>
          </pre>
        </Box>
        <Box className={[classes.codeBlock, classes.codeBlockCurl]}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <pre className={classes.container}>
              <code>{mapCurl(curl, botToken, filename, signature)}</code>
            </pre>
            <CopyToClipboard text={mapCurl(curl, botToken, filename, signature)}>
              <span
                className={classes.copyClipboard}
                onClick={event => onCopy(event)}
                onKeyDown={() => {}}
              >
                <Tooltip title={copyText} open={isCopied} placement="top">
                  <FileCopyIcon style={{ fontSize: "20", cursor: "pointer" }} />
                </Tooltip>
              </span>
            </CopyToClipboard>
          </Box>
        </Box>
      </AccordionDetails>
    </div>
  );
};

export default CurlPreview;
